<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="dashboard"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
            ></site-header>
            <ActiveFilters :filters="getFiltersUser" :sort="getSorts"/>
            <ClientSelectedAllItems v-if="getAllUserSelected" />
            <ClientSelectedItems v-if="!getAllUserSelected && getUserSelected.length"/>
          </v-col>
        </v-row>
      </div>
    </header>
    <main>
      <div>
        <v-row class="justify-content-center pb-4 wa__clients">
          <v-col cols="12" md="2">
            <ClientFilter />
          </v-col>
          <v-col cols="12" md="10">
            <ClientItems />
          </v-col>
        </v-row>
      </div>
    </main>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
    <div class="text-center">
      <ModalClient />
      <ModalStatus />
    </div>
  </div>
</template>
<script>
import ClientFilter from "../components/Client/Filter";
import ClientItems from "../components/Client/Items";
import ModalClient from "../components/Request/ModalClient.vue";
import ModalStatus from "../components/Client/ModalStatus.vue";
import ClientSelectedItems from "../components/Client/SelectedItems.vue"
import ClientSelectedAllItems from "../components/Client/SelectedAllItmes.vue"
import ActiveFilters from "../components/Client/ActiveFilters";
import {mapGetters,mapMutations} from "vuex"
export default {
  components: {
    ClientItems,
    ClientFilter,
    ModalClient,
    ModalStatus,
    ClientSelectedItems,
    ClientSelectedAllItems,
    ActiveFilters
  },
  computed:{
    ...mapGetters('user', ['getUserSelected','getAllUserSelected',"getFiltersUser", "getSorts"]),
  },
  methods: {
    ...mapMutations('user', ['DESTROY_HISTORY']),
  },
  destroyed(){
    this.DESTROY_HISTORY()
  }
};
</script>
<style lang="scss" scoped>
.wa__clients > .col-3 {
  max-width: 18%;
}
.wa__clients > .col-9 {
  padding-left: 0;
  flex: 0 0 79%;
  max-width: 79%;
}
</style>
